import React, { useEffect, useState, useContext } from 'react';
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext';
import { Container, Typography, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, Box } from '@mui/material';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

const UserVotes = () => {
  const { user } = useContext(AuthContext);
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    const fetchUserVotes = async () => {
      try {
        const response = await axios.get(`/votes/user/${user._id}`);
        console.log('Votes data:', response.data); // Log the response data
        setVotes(response.data);
      } catch (error) {
        console.error('Error fetching user votes:', error);
      }
    };

    fetchUserVotes();
  }, [user]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ mr: 2, bgcolor: 'secondary.main', width: 56, height: 56 }}>
            <HowToVoteIcon fontSize="large" />
          </Avatar>
          <Typography variant="h4" gutterBottom>
            My Votes
          </Typography>
        </Box>
        <List>
          {votes.map(vote => {
            // Ensure we safely access nested properties
            const electionTitle = vote?.roleId?.electionId?.Title || 'N/A';
            const candidateFirstName = vote?.roleId?.userId?.firstName || 'N/A';
            const candidateLastName = vote?.roleId?.userId?.lastName || 'N/A';
            const voteDate = new Date(vote.createdAt).toLocaleString();

            return (
              <ListItem key={vote._id}>
                <ListItemAvatar>
                  <Avatar>
                    <HowToVoteIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`Election: ${electionTitle}`}
                  secondary={`Candidate: ${candidateFirstName} ${candidateLastName} - Voted on: ${voteDate}`}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Container>
  );
};

export default UserVotes;
