import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Box, IconButton, Button, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RoleForm from './RoleForm';

const RolesTable = () => {
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editingRole, setEditingRole] = useState(null);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/roles');
      const data = await response.json();
      console.log('Fetched roles:', data); // Log the fetched roles
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleEdit = (role) => {
    setEditingRole(role);
    setModalOpen(true);
  };

  const handleDelete = async (roleId) => {
    try {
      await fetch(`https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/roles/${roleId}`, {
        method: 'DELETE',
      });
      setRoles(roles.filter(role => role._id !== roleId));
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const handleAddRole = () => {
    setEditingRole(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditingRole(null);
  };

  const filteredRoles = roles.filter((role) => {
    const userFirstName = role.userId?.firstName?.toLowerCase() || '';
    const userLastName = role.userId?.lastName?.toLowerCase() || '';
    const userEmail = role.userId?.email?.toLowerCase() || '';
    const electionTitle = role.electionId?.Title?.toLowerCase() || ''; // Ensure to use Title field
    const roleName = role.role?.toLowerCase() || '';

    const searchTerm = search.toLowerCase();

    return (
      userFirstName.includes(searchTerm) ||
      userLastName.includes(searchTerm) ||
      userEmail.includes(searchTerm) ||
      electionTitle.includes(searchTerm) ||
      roleName.includes(searchTerm)
    );
  });

  return (
    <Paper>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearch}
        />
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddRole}>
          Add Role
        </Button>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="roles table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Election</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRoles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role) => (
              <TableRow key={role._id}>
                <TableCell>{role.userId?.firstName}</TableCell>
                <TableCell>{role.userId?.lastName}</TableCell>
                <TableCell>{role.userId?.email}</TableCell>
                <TableCell>{role.electionId?.Title}</TableCell> {/* Corrected field name */}
                <TableCell>{role.role}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(role)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(role._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRoles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, boxShadow: 24, maxWidth: 600, width: '100%' }}>
          <RoleForm role={editingRole} onClose={handleCloseModal} onSave={fetchRoles} />
        </Box>
      </Modal>
    </Paper>
  );
};

export default RolesTable;
