import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Container, Typography, Paper, Box, Divider, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserVotes from './UserVotes';
import VerificationSelection from './verification/VerificationSelection'; // Import the VerificationSelection component

const UserProfile = () => {
  const { user } = useContext(AuthContext);
  const [isVerified, setIsVerified] = useState(false); // State to track if verification is completed

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ mr: 2, bgcolor: 'primary.main', width: 56, height: 56 }}>
            <AccountCircleIcon fontSize="large" />
          </Avatar>
          <Typography variant="h5" component="div" gutterBottom>
            User Profile
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1"><strong>Name:</strong> {user.firstName} {user.lastName}</Typography>
          <Typography variant="subtitle1"><strong>Email:</strong> {user.email}</Typography>
          <Typography variant="subtitle1"><strong>Phone Number:</strong> {user.phoneNumber}</Typography>
          <Typography variant="subtitle1"><strong>Date of Birth:</strong> {new Date(user.dateOfBirth).toLocaleDateString()}</Typography>
        </Box>
      </Paper>
      {!isVerified ? (
        <VerificationSelection onVerified={() => setIsVerified(true)} context="profile" />
      ) : (
        <UserVotes />
      )}
    </Container>
  );
};

export default UserProfile;
