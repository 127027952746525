import React, { useState, useEffect, useRef } from 'react';
import firebase from './firebaseConfig'; // Adjust the path as necessary
import 'firebase/compat/auth';
import { Button, TextField, Container, Typography } from '@mui/material';

const PhoneVerification = ({ onVerified }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const recaptchaVerifier = useRef(null);

  useEffect(() => {
    if (step === 1) {
      renderRecaptcha();
    }
  }, [step]);

  const renderRecaptcha = () => {
    if (!recaptchaVerifier.current) {
      recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'normal',
        callback: (response) => {
          console.log('reCAPTCHA verified');
        },
        'expired-callback': () => {
          setError('reCAPTCHA expired, please try again.');
        }
      });
    }

    recaptchaVerifier.current.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSendCode = (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phoneNumber)) {
      alert('Please enter a valid phone number in E.164 format.');
      return;
    }

    const appVerifier = recaptchaVerifier.current;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setStep(2);
      })
      .catch((error) => {
        console.error('Error during phone number verification:', error);
        setError('Error during phone number verification');
      });
  };

  const handleVerifyCode = (e) => {
    e.preventDefault();

    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    firebase.auth().signInWithCredential(credential)
      .then((result) => {
        console.log('Phone number verified:', result.user.phoneNumber);
        window.alert('Phone number verified: ' + result.user.phoneNumber);
        onVerified(); // Trigger verification callback
      })
      .catch((error) => {
        console.error('Error during phone number verification:', error);
        setError('Error verifying code');
      });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Phone Number Authentication
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {step === 1 && (
        <form onSubmit={handleSendCode}>
          <TextField
            type="tel"
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <div id="recaptcha-container"></div>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleVerifyCode}>
          <TextField
            type="text"
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
            Verify
          </Button>
        </form>
      )}
    </Container>
  );
};

export default PhoneVerification;
