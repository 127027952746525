import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Container, Box, Typography, Grid, Paper, TextField, IconButton, Tooltip, Menu, MenuItem, Button, ListItemIcon, ListItemText, Chip, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BallotIcon from '@mui/icons-material/Ballot';
import CategoryIcon from '@mui/icons-material/Category';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import the AuthContext

const HomePage = () => {
  const [elections, setElections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryColors, setCategoryColors] = useState({});
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const { user, isAuthenticated } = useContext(AuthContext); // Use the AuthContext

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserElections(user._id);
    } else {
      fetchAllElections();
    }
    fetchCategories();
  }, [isAuthenticated, user?._id]); // Add user?._id to dependencies

  const generateCategoryColors = useCallback(() => {
    const colors = categories.reduce((acc, category, index) => {
      acc[category._id] = `hsl(${(index * 360) / categories.length}, 70%, 50%)`;
      return acc;
    }, {});
    setCategoryColors(colors);
  }, [categories]);

  useEffect(() => {
    if (categories.length > 0) {
      generateCategoryColors();
    }
  }, [categories, generateCategoryColors]);

  const fetchAllElections = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections');
      const data = await response.json();
      setElections(data);
    } catch (error) {
      console.error('Error fetching all elections:', error);
    }
  };

  const fetchUserElections = async (userId) => {
    try {
      const response = await fetch(`https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/user-elections/${userId}`);
      const data = await response.json();
      setElections(data);
    } catch (error) {
      console.error('Error fetching user elections:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/categories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleMenuOpen = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl) => () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = (categoryId) => {
    setCategory(categoryId);
    handleMenuClose(setAnchorEl1)();
  };

  const handleStatusSelect = (status) => {
    setStatus(status);
    handleMenuClose(setAnchorEl2)();
  };

  const handleResultsOpen = async (electionId) => {
    try {
      const response = await fetch(`https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/votes/counts/${electionId}`);
      const data = await response.json();
      setResults(data);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  const handleResultsClose = () => {
    setOpen(false);
    setResults([]);
  };

  const filteredElections = elections.filter(election => {
    const searchMatch = election.Title.toLowerCase().includes(search.toLowerCase()) || election.Description.toLowerCase().includes(search.toLowerCase());
    const categoryMatch = !category || election.CategoryID === category;
    const statusMatch = !status || (status === 'Ongoing' && election.isActive) || (status === 'Finished' && !election.isActive);
    return searchMatch && categoryMatch && statusMatch;
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom>
          Elections
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <Box>
            <Tooltip title="Categories">
              <Button
                variant="contained"
                color="primary"
                aria-controls="categories-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen(setAnchorEl1)}
                endIcon={<ArrowDropDownIcon />}
                sx={{ mr: 1 }}
              >
                Categories
              </Button>
            </Tooltip>
            <Menu
              id="categories-menu"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleMenuClose(setAnchorEl1)}
            >
              <MenuItem onClick={() => handleCategorySelect('')}>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="All" />
              </MenuItem>
              {categories.map(category => (
                <MenuItem key={category._id} onClick={() => handleCategorySelect(category._id)}>
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: categoryColors[category._id] || '#000',
                        display: 'inline-block',
                        borderRadius: '50%',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={category.Name} />
                </MenuItem>
              ))}
            </Menu>
            <Tooltip title="Status">
              <Button
                variant="contained"
                color="secondary"
                aria-controls="status-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen(setAnchorEl2)}
                endIcon={<ArrowDropDownIcon />}
              >
                Status
              </Button>
            </Tooltip>
            <Menu
              id="status-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleMenuClose(setAnchorEl2)}
            >
              <MenuItem onClick={() => handleStatusSelect('Ongoing')}>
                <ListItemIcon>
                  <PlayCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Ongoing" />
              </MenuItem>
              <MenuItem onClick={() => handleStatusSelect('Finished')}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Finished" />
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {filteredElections.map((election) => (
            <Grid item xs={12} sm={6} md={4} key={election._id}>
              <Paper
                elevation={2}
                sx={{
                  height: 180,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 1,
                  cursor: election.isActive ? 'pointer' : 'default',
                  borderLeft: `5px solid ${categoryColors[election.CategoryID] || '#000'}`,
                  p: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: election.isActive ? 'scale(1.05)' : 'none',
                  },
                }}
                onClick={() => election.isActive ? navigate(`/election/${election._id}/vote`, { state: { context: 'election' } }) : handleResultsOpen(election._id)}
              >
                <Box textAlign="center">
                  <BallotIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography variant="h6" noWrap>
                    {election.Title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" noWrap>
                    {election.Description}
                  </Typography>
                  {election.isActive ? (
                    <Chip label="Ongoing" color="success" sx={{ mt: 1 }} />
                  ) : (
                    <Box>
                      <Chip label="Finished" color="error" sx={{ mt: 1 }} />
                      {user && (
                        <Button 
                          variant="outlined" 
                          color="primary" 
                          sx={{ mt: 1 }}
                          onClick={() => handleResultsOpen(election._id)}
                        >
                          View Results
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Dialog open={open} onClose={handleResultsClose}>
        <DialogTitle>Election Results</DialogTitle>
        <DialogContent>
          {results.map((result, index) => (
            <Box key={result._id} display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
              <Typography variant="body1" color={index === 0 ? 'primary' : 'textPrimary'}>{result.candidateName}</Typography>
              <Chip label={`${result.totalVotes} votes`} color={index === 0 ? 'primary' : 'default'} />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResultsClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HomePage;
