import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, Modal } from '@mui/material';

const RoleForm = ({ role, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    userId: '',
    electionId: '',
    role: '',
  });

  const [users, setUsers] = useState([]);
  const [elections, setElections] = useState([]);

  useEffect(() => {
    if (role) {
      setFormData({
        userId: role.userId,
        electionId: role.electionId,
        role: role.role,
      });
    }
    fetchUsers();
    fetchElections();
  }, [role]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchElections = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections'); // Adjust API endpoint as necessary
      const data = await response.json();
      setElections(data);
    } catch (error) {
      console.error('Error fetching elections:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = role ? 'PUT' : 'POST';
      const url = role ? `https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/roles/${role._id}` : 'https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/roles';
      await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving role:', error);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        boxShadow: 24,
        maxWidth: 600,
        width: '100%',
      }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          <TextField
            select
            label="User"
            name="userId"
            value={formData.userId}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {users.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Election"
            name="electionId"
            value={formData.electionId}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {elections.map((election) => (
              <MenuItem key={election._id} value={election._id}>
                {election.Title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="candidat">Candidat</MenuItem>
            <MenuItem value="electeur">Electeur</MenuItem>
            <MenuItem value="electeur">Admin</MenuItem>
          </TextField>
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            {role ? 'Update' : 'Add'} Role
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RoleForm;
