import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem } from '@mui/material';

const UserForm = ({ user, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    dateOfBirth: '',
    phoneNumber: '',
    role: '',
    election: '',
  });

  const [elections, setElections] = useState([]);

  useEffect(() => {
    if (user) {
      setFormData({
        ...user,
        dateOfBirth: user.dateOfBirth.split('T')[0],
        role: user.roles.length ? user.roles[0].role : '',
        election: user.roles.length ? user.roles[0].electionId : '',
      });
    }
    fetchElections();
  }, [user]);

  const fetchElections = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections'); // Adjust API endpoint as necessary
      const data = await response.json();
      console.log('Fetched elections:', data); // Log the fetched data
      setElections(data);
    } catch (error) {
      console.error('Error fetching elections:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = user ? 'PUT' : 'POST';
      const url = user ? `https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/${user._id}` : 'https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users';
      const body = {
        ...formData,
        electionId: formData.election,
        roleType: formData.role,
      };
      console.log('Submitting form data:', body); // Log form data for debugging
      await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, bgcolor: 'background.paper' }}>
      <TextField
        label="First Name"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Date of Birth"
        name="dateOfBirth"
        type="date"
        value={formData.dateOfBirth}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        select
        label="Election"
        name="election"
        value={formData.election}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {elections.map((election) => (
          <MenuItem key={election._id} value={election._id}>
            {election.Title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Role"
        name="role"
        value={formData.role}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="candidat">Candidat</MenuItem>
        <MenuItem value="electeur">Electeur</MenuItem>
        <MenuItem value="electeur">Admin</MenuItem>
      </TextField>
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        {user ? 'Update' : 'Add'} User
      </Button>
    </Box>
  );
};

export default UserForm;
