import React, { useState } from 'react';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import UsersTable from './UsersTable';
import ElectionsTable from './ElectionsTable';
import CategoriesTable from './CategoriesTable';
import RolesTable from './RolesTable';
import PeopleIcon from '@mui/icons-material/People';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CategoryIcon from '@mui/icons-material/Category';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ mt: 8, display: 'flex', width: '100%' }}>
      <Box sx={{ borderRight: 1, borderColor: 'divider', width: '200px' }}>
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Dashboard tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Roles" icon={<GroupWorkIcon />} iconPosition="start" />
          <Tab label="Utilisateurs" icon={<PeopleIcon />} iconPosition="start" />
          <Tab label="Categories" icon={<CategoryIcon />} iconPosition="start" />
          <Tab label="Elections" icon={<HowToVoteIcon />} iconPosition="start" />
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {selectedTab === 0 && (
          <Box>
            <Typography variant="h6">Roles Table</Typography>
            <RolesTable />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            <Typography variant="h6">Utilisateurs Table</Typography>
            <UsersTable />
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            <Typography variant="h6">Categories Table</Typography>
            <CategoriesTable />
          </Box>
        )}
        {selectedTab === 3 && (
          <Box>
            <Typography variant="h6">Elections Table</Typography>
            <ElectionsTable />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
