import React, { useState, useContext } from 'react';
import PhoneVerification from './PhoneVerification';
import FaceVerification from './FaceVerification';
import EmailVerification from './EmailVerification';
import { AuthContext } from '../../context/AuthContext'; 
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FaceIcon from '@mui/icons-material/Face';
import EmailIcon from '@mui/icons-material/Email';

const VerificationSelection = ({ onVerified }) => {
  const { user } = useContext(AuthContext);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Get election id from URL if available
  const context = location.state?.context || 'profile'; // Default to profile if no context is provided

  const handleMethodSelection = (method) => {
    setVerificationMethod(method);
  };

  const handleVerificationSuccess = () => {
    onVerified();
    if (context === 'election') {
      navigate(`/election/${id}/vote`); // Adjust the path to your voting page
    }
  };

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      {!verificationMethod ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Select Verification Method
          </Typography>
          <Box mt={2} width="100%" maxWidth="300px">
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<PhoneIcon />} 
              onClick={() => handleMethodSelection('phone')}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Phone Verification
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<FaceIcon />} 
              onClick={() => handleMethodSelection('face')}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Face Verification
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<EmailIcon />} 
              onClick={() => handleMethodSelection('email')}
              fullWidth
            >
              Email Verification
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {verificationMethod === 'phone' && <PhoneVerification onVerified={handleVerificationSuccess} />}
          {verificationMethod === 'face' && <FaceVerification onVerified={handleVerificationSuccess} />}
          {verificationMethod === 'email' && <EmailVerification onVerified={handleVerificationSuccess} userEmail={user?.email} />}
        </>
      )}
    </Container>
  );
};

export default VerificationSelection;
