import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EmailVerification = ({ onVerified, userEmail }) => {
  const [email, setEmail] = useState(userEmail);
  const [verificationCode, setVerificationCode] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  const handleSendEmail = async () => {
    try {
      await axios.post('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/email-verification/send-verification-email', { email });
      setSent(true);
    } catch (error) {
      console.error('Error sending verification email:', error);
      setError('Error sending verification email');
    }
  };

  const handleVerifyCode = async () => {
    try {
      await axios.post('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/email-verification/verify-code', { email, verificationCode });
      onVerified();
    } catch (error) {
      console.error('Error verifying code:', error.response?.data || error.message);
      setError('Invalid verification code');
    }
  };

  return (
    <div>
      <h1>Email Verification</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!sent ? (
        <div>
          <button onClick={handleSendEmail}>Send Verification Email</button>
        </div>
      ) : (
        <div>
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <button onClick={handleVerifyCode}>Verify Code</button>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
