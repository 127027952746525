import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import './FaceVerification.css';

const FaceVerification = ({ onVerified }) => {
  const [idPhoto, setIdPhoto] = useState(null);
  const [facePhoto, setFacePhoto] = useState(null);
  const [error, setError] = useState('');
  const webcamRef = useRef(null);

  const captureFace = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFacePhoto(imageSrc);
  };

  const handleIdPhotoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setIdPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('idPhoto', idPhoto);
    formData.append('facePhoto', facePhoto);

    try {
      const response = await axios.post('https://0c33-196-75-171-150.ngrok-free.app/verify', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.match) {
        onVerified();
      } else {
        setError('Face mismatch detected. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading photos:', error);
      setError('Error uploading photos. Please try again.');
    }
  };

  return (
    <div className="face-verification">
      <h1>Face Verification</h1>
      {error && <p className="error">{error}</p>}
      <div className="content">
        <div className="input-group">
          <label htmlFor="idPhoto">Upload ID Photo:</label>
          <input type="file" accept="image/*" onChange={handleIdPhotoChange} id="idPhoto" />
        </div>
        <div className="webcam-container">
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className="webcam" />
          <button className="button" onClick={captureFace}>Capture Face</button>
          {facePhoto && (
            <div className="image-preview">
              <h2>Captured Face:</h2>
              <img src={facePhoto} alt="Face" className="captured-face" />
            </div>
          )}
        </div>
      </div>
      <button className="button submit" onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default FaceVerification;
