import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Modal } from '@mui/material';

const CategoryForm = ({ category, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Description: '',
  });

  useEffect(() => {
    if (category) {
      setFormData(category);
    }
  }, [category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = category ? 'PUT' : 'POST';
      const url = category ? `https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/categories/${category._id}` : 'https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/categories';
      await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        boxShadow: 24,
        maxWidth: 600,
        width: '100%',
      }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          <TextField
            label="Name"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            {category ? 'Update' : 'Add'} Category
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryForm;
