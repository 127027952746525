import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, CircularProgress, Box, Alert } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axios from '../api/axios';
import VerificationSelection from './verification/VerificationSelection'; // Import the VerificationSelection component
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const VotePage = () => {
  const { id } = useParams(); // Get election id from URL
  const { user } = useContext(AuthContext);
  const [election, setElection] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidateRoleId, setSelectedCandidateRoleId] = useState('');
  const [hasVoted, setHasVoted] = useState(false); // State to track if user has voted
  const [loading, setLoading] = useState(true); // State to track loading
  const [error, setError] = useState(''); // State to track errors
  const [isVerified, setIsVerified] = useState(false); // State to track if verification is completed
  const navigate = useNavigate();

  useEffect(() => {
    const fetchElectionData = async () => {
      try {
        const response = await axios.get(`/elections/${id}`);
        setElection(response.data);
      } catch (error) {
        setError('Error fetching election');
        console.error('Error fetching election:', error);
      }
    };

    const fetchCandidatesData = async () => {
      try {
        const response = await axios.get(`/elections/${id}/candidates`);
        setCandidates(response.data);
      } catch (error) {
        setError('Error fetching candidates');
        console.error('Error fetching candidates:', error);
      }
    };

    const checkUserVoteStatus = async () => {
      try {
        const response = await axios.get(`/votes/hasVoted/${user._id}/${id}`);
        setHasVoted(response.data.hasVoted);
      } catch (error) {
        setError('Error checking vote status');
        console.error('Error checking vote status:', error);
      }
    };

    setLoading(true);
    fetchElectionData();
    fetchCandidatesData();
    checkUserVoteStatus();
    setLoading(false);
  }, [id, user._id]);

  const handleVote = async () => {
    try {
      await axios.post('/votes', {
        userId: user._id,
        candidateRoleId: selectedCandidateRoleId
      }, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}` // Add authorization header if needed
        }
      });
      navigate('/home'); // Redirect to home after voting
    } catch (error) {
      setError('Error casting vote');
      console.error('Error casting vote:', error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!isVerified) {
    return <VerificationSelection onVerified={() => setIsVerified(true)} />;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <ErrorIcon fontSize="inherit" sx={{ mr: 1 }} />
            {error}
          </Alert>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <HowToVoteIcon sx={{ fontSize: 40, mr: 1 }} />
          <Typography variant="h4">
            {election ? election.Title : 'Loading...'}
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          {election ? election.Description : 'Loading...'}
        </Typography>
        <FormControl component="fieldset" disabled={hasVoted} sx={{ mt: 2 }}>
          <FormLabel component="legend">Select a Candidate</FormLabel>
          <RadioGroup value={selectedCandidateRoleId} onChange={(e) => setSelectedCandidateRoleId(e.target.value)}>
            {candidates.map((candidate) => (
              <FormControlLabel key={candidate.roleId} value={candidate.roleId} control={<Radio />} label={`${candidate.firstName} ${candidate.lastName}`} />
            ))}
          </RadioGroup>
        </FormControl>
        {hasVoted ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            <CheckCircleIcon fontSize="inherit" sx={{ mr: 1 }} />
            You have already voted in this election.
          </Alert>
        ) : (
          <Button variant="contained" color="primary" onClick={handleVote} sx={{ mt: 2 }} disabled={!selectedCandidateRoleId}>
            Vote
          </Button>
        )}
      </Paper>
    </Container>
  );
};

export default VotePage;
