import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDk99mFO7g-d0XYDa_Wa5j1AacifFeFPXo",
  authDomain: "sms-5f4a2.firebaseapp.com",
  projectId: "sms-5f4a2",
  storageBucket: "sms-5f4a2.appspot.com",
  messagingSenderId: "293448425052",
  appId: "1:293448425052:web:d74b888ef8864032831099",
  measurementId: "G-FJF9DZM487"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
