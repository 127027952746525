import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Box, IconButton, Button, Modal, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UserForm from './UserForm';

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [elections, setElections] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedElection, setSelectedElection] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchElections();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchElections = async () => {
    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections');
      const data = await response.json();
      setElections(data);
    } catch (error) {
      console.error('Error fetching elections:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setModalOpen(true);
  };

  const handleDelete = async (userId) => {
    try {
      await fetch(`https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/${userId}`, {
        method: 'DELETE',
      });
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleAddUser = () => {
    setEditingUser(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditingUser(null);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file || !selectedElection) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('electionId', selectedElection);

    try {
      const response = await fetch('https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/users/import', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        fetchUsers();
        setFile(null);
        setSelectedElection('');
        alert('Users imported successfully');
      } else {
        alert('Error importing users');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const filteredUsers = users.filter((user) =>
    (user.firstName && user.firstName.toLowerCase().includes(search.toLowerCase())) ||
    (user.lastName && user.lastName.toLowerCase().includes(search.toLowerCase())) ||
    (user.email && user.email.toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <Paper>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearch}
        />
        <Box>
          <TextField
            select
            label="Select Election"
            value={selectedElection}
            onChange={(e) => setSelectedElection(e.target.value)}
            fullWidth
            margin="normal"
          >
            {elections.map((election) => (
              <MenuItem key={election._id} value={election._id}>
                {election.Title}
              </MenuItem>
            ))}
          </TextField>
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="upload-file"
          />
          <label htmlFor="upload-file">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
              sx={{ mr: 2 }}
            >
              Import Users
            </Button>
          </label>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
        disabled={!file || !selectedElection}
        sx={{ m: 2 }}
      >
        Upload
      </Button>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{new Date(user.dateOfBirth).toLocaleDateString()}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{user.roles.length ? user.roles[0].role : 'N/A'}</TableCell>
                <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
                <TableCell>{new Date(user.updatedAt).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(user._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, boxShadow: 24, maxWidth: 600, width: '100%' }}>
          <UserForm user={editingUser} onClose={handleCloseModal} onSave={fetchUsers} />
        </Box>
      </Modal>
    </Paper>
  );
};

export default UsersTable;
