import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, Select, InputLabel, FormControl, Modal } from '@mui/material';

const ElectionForm = ({ election, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    DateStart: '',
    DateEnd: '',
    CategoryID: '',
    Title: '',
    Description: '',
    isActive: true,
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (election) {
      setFormData({
        DateStart: election.DateStart.split('T')[0],
        DateEnd: election.DateEnd.split('T')[0],
        CategoryID: election.CategoryID,
        Title: election.Title,
        Description: election.Description,
        isActive: election.isActive,
      });
    }
  }, [election]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://ydays2024backend-642c91475e97.herokuapp.com/api/categories');
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = election ? 'PUT' : 'POST';
      const url = election ? `https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections/${election._id}` : 'https://ydayselectedbacken-458e79f51ef9.herokuapp.com/api/elections';
      await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving election:', error);
    }
  };
  
  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        boxShadow: 24,
        maxWidth: 600,
        width: '100%',
      }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          <TextField
            label="Title"
            name="Title"
            value={formData.Title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date Start"
            name="DateStart"
            type="date"
            value={formData.DateStart}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Date End"
            name="DateEnd"
            type="date"
            value={formData.DateEnd}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              name="CategoryID"
              value={formData.CategoryID}
              onChange={handleChange}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>isActive</InputLabel>
            <Select
              label="isActive"
              name="isActive"
              value={formData.isActive}
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            {election ? 'Update' : 'Add'} Election
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ElectionForm;
